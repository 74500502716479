import React from 'react'
import './home.css'
import NavPanel from '../assets/NavPanel/NavPanel';
import MainIntro from '../components/MainIntro/MainIntro';
import { motion } from 'framer-motion';
import { highlightText, animationTagStyle } from '../components/MainIntro/CustomStyles';
import Projects from '../components/Projects/Projects';
import WorkExp from '../components/WorkExp/WorkExp';
import Publications from '../components/Publications/Publications';
import Connect from '../components/Connect/Connect';

const Home = () => {

    const [displayStartAnimation, setDisplayStartAnimation] = React.useState(false);
    const [displayHome, setDisplayHome] = React.useState(false);

    React.useEffect(() => {
        setTimeout(() => {
            setDisplayStartAnimation(true);
        }, 1500);
        setTimeout(() => {
            setDisplayHome(true);
        }, 5000);
        setTimeout(() => {
            setDisplayStartAnimation(false);
        }, 9000);
    }, []);

    return (
        <>
            {
                displayStartAnimation && (
                    <motion.div
                        initial={{ opacity: 1, scale: 1, y: 0, height: '100vh', width: '100vw' }}
                        animate={{ opacity: 1, scale: 1, y: -500, height: '0vh', width: '100vw' }}
                        transition={{
                            duration: 1,
                            delay: 4,
                            transition: { ease: [0.79, 0.33, 0.14, 0.53] }
                        }}
                    >
                        <div className="starting-animation">
                            <div className="npm-install-code-container"
                                style={{
                                    ...animationTagStyle,
                                }}>
                                <h3 className="npm-install-code" style={{
                                    fontSize: 'calc(1.5rem + 0.8vw + 1vh)',
                                }}>
                                    Ashwin-MacBook-Pro portfolio % npm install <span style={{
                                        ...highlightText,
                                    }}>Ashwin</span>......
                                </h3>
                            </div>
                        </div>
                        <div className="starting-animation-mobile">
                            <div className="npm-install-code-container"
                                style={{
                                    ...animationTagStyle,
                                    
                                }}>
                                <h3 className="npm-install-code" style={{
                                    fontSize: 'calc(1.5rem + 0.8vw + 1vh)',
                                }}>
                                    npm install <span style={{
                                        ...highlightText,
                                    }}>Ashwin</span>......
                                </h3>
                            </div>
                        </div>
                    </motion.div>
                )
            }
            {
                displayHome && (
                    <div className="home-main-container">
                        <div className="main-intro-container">
                            <MainIntro />
                            <NavPanel />
                        </div>
                        <div id='projects'>
                            <motion.div
                                initial={{ opacity: 0, y: 100 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5 }}
                            >
                                <Projects />
                            </motion.div>
                        </div>
                        <div id='workexp'>
                            <motion.div
                                initial={{ opacity: 0, y: 100 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5 }}
                            >
                                <WorkExp />
                            </motion.div>
                        </div>
                        <div id='publications'>
                            <motion.div
                                initial={{ opacity: 0, y: 100 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5 }}
                            >
                                <Publications />
                            </motion.div>
                        </div>
                        <br />
                        <motion.div
                            initial={{ opacity: 0, y: 100 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5 }}
                            style={{
                                height: 'calc(30vh)',
                                width: '100%'
                            }}
                        >
                            <Connect />
                        </motion.div>
                        <br /><br />
                    </div>
                )
            }
            <ul className="circles">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
        </>
    )
}

export default Home