import React from 'react'
import { HTMLTagStyle, normalText, highlightText } from './CustomStyles'
import './MainIntro.css'

const MainIntro = () => {
  return (
    <>
      <div className="mainIntro-main">
        <div className="main-intro-code">
          <div className="main-content">
            <div className="content">
              <h3 style={{
                ...HTMLTagStyle,
                paddingLeft: '6rem',
              }}>
                &lt;header&gt; <br />
              </h3>
              <h4 style={{
                ...normalText,
                paddingLeft: '8rem',
                textAlign: 'left',
                fontSize: 'calc(2rem + 0.8vw + 1vh)',
              }}>
                Hi! I'm <span style={{
                  ...highlightText,
                  fontSize: 'calc(2rem + 0.8vw + 1vh)',
                }}>Ashwin</span> <br />
              </h4>
              <h3 style={{
                ...HTMLTagStyle,
                paddingLeft: '6rem',
              }}>
                &lt;/header&gt; <br />
              </h3>
              <h3 style={{
                ...HTMLTagStyle,
                paddingLeft: '6rem',
              }}>
                &lt;body&gt; <br />
              </h3>
              <h3 style={{
                ...HTMLTagStyle,
                paddingLeft: '8rem',
              }}>
                &lt;ul&gt; <br />
              </h3>
              <h4 style={{
                ...normalText,
                paddingLeft: '10rem',
                textAlign: 'left'
              }}>
                <span style={{
                  ...HTMLTagStyle
                }}>&lt;li&gt;</span> <span style={{
                  ...highlightText
                }}>Full Stack Web Developer</span>
                <span style={{
                  ...HTMLTagStyle
                }}>&lt;/li&gt;</span> <br />
              </h4>
              <h4 style={{
                ...normalText,
                paddingLeft: '10rem',
                textAlign: 'left'
              }}>
                <span style={{
                  ...HTMLTagStyle
                }}>&lt;li&gt;</span> <span style={{
                  ...highlightText
                }}>Software Developer</span>
                <span style={{
                  ...HTMLTagStyle
                }}>&lt;/li&gt;</span> <br />
              </h4>
              <h4 style={{
                ...normalText,
                paddingLeft: '10rem',
                textAlign: 'left'
              }}>
                <span style={{
                  ...HTMLTagStyle
                }}>&lt;li&gt;</span> <br /> <span style={{
                  ...highlightText,
                  paddingLeft: '2rem',
                }}>Graduate Computer Science Student</span> <br />
                <span style={{
                  ...HTMLTagStyle,
                  paddingLeft: '2rem',
                }}>&lt;span&gt;</span> <br />
                <span style={{
                  ...normalText,
                  paddingLeft: '4rem',
                }}>at Northeastern University, Boston</span> <br />
                <span style={{
                  ...HTMLTagStyle,
                  paddingLeft: '2rem',
                }}>&lt;/span&gt;</span> <br />
                <span style={{
                  ...HTMLTagStyle
                }}>&lt;/li&gt;</span> <br />
              </h4>
              <h3 style={{
                ...HTMLTagStyle,
                paddingLeft: '8rem',
              }}>
                &lt;ul&gt; <br />
              </h3>
              <h3 style={{
                ...HTMLTagStyle,
                paddingLeft: '6rem',
              }}>
                &lt;/body&gt; <br />
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="mainIntro-main-mobile">
        <h2 style={{
          ...normalText,
          textAlign: 'center',
          fontSize: 'calc(2rem + 0.8vw + 1vh)',
        }}>
          Hi! I'm   <span style={{
            ...highlightText,
            fontSize: 'calc(2rem + 0.8vw + 1vh)',
          }}>Ashwin</span> <br />
        </h2>
        <h4 style={{
          ...normalText,
          textAlign: 'center',
        }}>
          <span style={{
            ...HTMLTagStyle
          }}>&lt;li&gt;</span> <span style={{
            ...highlightText,
            fontSize: 'calc(0.8em)',
          }}>Full Stack Web Developer</span>
          <span style={{
            ...HTMLTagStyle
          }}>&lt;/li&gt;</span> <br />
        </h4>
        <h4 style={{
          ...normalText,
          textAlign: 'center',
        }}>
          <span style={{
            ...HTMLTagStyle
          }}>&lt;li&gt;</span> <span style={{
            ...highlightText
          }}>Software Developer</span>
          <span style={{
            ...HTMLTagStyle
          }}>&lt;/li&gt;</span> <br />
        </h4>
        <h4 style={{
          ...normalText,
          textAlign: 'center',
        }}>
          <span style={{
            ...highlightText,
          }}>Graduate Computer <br /> Science Student</span>
          <br />
          <span style={{
            ...normalText,
            textAlign: 'center',
            fontSize: 'calc(0.6em)',
          }}>Northeastern University, Boston</span> <br />
        </h4>
        <br />
        <div className="navigation-buttons">
          <button>
            <a href="#projects" onClick={(event) => {
              event.preventDefault();
              const section2 = document.getElementById('projects');
              section2.scrollIntoView({ behavior: 'smooth' });
            }}>
              Projects
            </a>
          </button>
          <button>
            <a href="#workexp" onClick={(event) => {
              event.preventDefault();
              const section2 = document.getElementById('workexp');
              section2.scrollIntoView({ behavior: 'smooth' });
            }}>
              WorkExp
            </a>
          </button>
          <button>
            <a href="#publications" onClick={(event) => {
              event.preventDefault();
              const section2 = document.getElementById('publications');
              section2.scrollIntoView({ behavior: 'smooth' });
            }}>
              Publications
            </a>
          </button>
        </div>
      </div>
    </>
  )
}

export default MainIntro