import React from 'react'
import powstikLogo from '../images/powstik_logo.png';
import irctcLogo from '../images/irctc1.png';
import vitLogo from '../images/vit-logo-dark.png';
import { highlightText, animationTagStyle } from '../MainIntro/CustomStyles';

const WorkExp = () => {

    const workExperience = [
        {
            companyName: 'Powstik',
            companyRole: 'Backend Developer',
            companyRoleDescription: 'Created and structured the complete e-commerce database, reducing data retrieval time by 40% than existing system.',
            companyLogo: [
                powstikLogo
            ],
            startDate: 'April 2022',
            endDate: 'July 2022'
        },
        {
            companyName: 'Indian Railways Project',
            companyRole: 'Backend Developer',
            companyRoleDescription: 'Researched and designed a framework and architecture for automotive railway asset management, achieving a 75% increase in efficiency.',
            companyLogo: [
                irctcLogo,
                vitLogo
            ],
            startDate: 'May 2022',
            endDate: 'February 2023'
        }
    ]

    return (
        <>
            <div className="main-workExp-container">
                <div className="projects-heading">
                    <h1 style={{
                        ...animationTagStyle,
                        fontSize: 'calc(2rem + 1vw + 2vh)',
                        textAlign: 'center',
                    }}>
                        <span style={{
                            ...highlightText,
                            fontSize: 'calc(2rem + 1vw + 2vh)',
                        }}>
                            &lt;
                        </span>
                        Work Experience
                        <span style={{
                            ...highlightText,
                            fontSize: 'calc(2rem + 1vw + 2vh)',
                        }}>
                            &gt;
                        </span>
                    </h1>
                </div>
                <div className="work-experience-main-container">
                    <br /> <br />
                    <div className="work-exp-list-container">
                        {
                            workExperience.map((work) => (
                                <div className="">
                                    <div className="work-exp-unit">
                                        <div className="company-logo">
                                            {/* <img src={work.companyLogo} alt="" /> */}
                                            {
                                                work.companyLogo.map((logo) => (
                                                    <img src={logo} alt="" />
                                                ))
                                            }
                                        </div>
                                        <div className="work-exp-unit-container">
                                            <div className="timeline-line">
                                                <div className="cirle-line">
                                                    <div className="round"></div>
                                                    <div className="vl"></div>
                                                    <div className="round"></div>
                                                </div>
                                            </div>
                                            <div className="work-exp-desc">
                                                <div className="date-container">
                                                    <h4>
                                                        {
                                                            work.startDate
                                                        }
                                                    </h4>
                                                </div>
                                                <div className="work-exp-info-container">
                                                    <div className="company-name">
                                                        <h2>
                                                            {
                                                                work.companyName
                                                            }
                                                        </h2>
                                                    </div>
                                                    <div className="company-role">
                                                        <h3>
                                                            {
                                                                work.companyRole
                                                            }
                                                        </h3>
                                                    </div>
                                                    <div className="company-role-desc">
                                                        <h4>
                                                            {
                                                                work.companyRoleDescription
                                                            }
                                                        </h4>
                                                    </div>
                                                </div>
                                                <div className="date-container">
                                                    <h4>
                                                        {
                                                            work.endDate
                                                        }
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                    <hr className='hr-tag' />
                                    <br />
                                    <br />
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default WorkExp