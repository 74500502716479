export const HTMLTagStyle = {
    color: '#6c757d',
    fontSize: 'calc(0.25rem + 0.5vw + 0.5vh)',
    fontFamily: 'Titillium Web',
    textAlign: 'left'
};

export const animationTagStyle = {
    color: '#6c757d',
    fontFamily: 'VT323',
    textAlign: 'left'
}

export const normalText = {
    color: 'white',
    fontSize: 'calc(1rem + 0.8vw + 1vh)',
    fontFamily: 'Poppins'
};

export const highlightText = {
    color: 'aqua',
    fontSize: 'calc(1rem + 0.8vw + 1vh)',
    fontFamily: 'Poppins',
    fontWeight: 'bold'
}

export const backdropText = {
    color: 'white',
    fontSize: 'calc(7.5em)',
    font: 'Oswald',
    zIndex: '-1',
    display: 'none'
}


// export default {
//     HTMLTagStyle,
//     normalText,
//     highlightText,
//     backdropText
// };