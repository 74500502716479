import React from 'react'
import { highlightText, animationTagStyle } from '../MainIntro/CustomStyles';
import './Publications.css'

const PublicationsList = [
    {
        type : 'Research Paper',
        name: 'Analysis of Convolutional Neural Networks on Indian Food Detection and Estimation of Calories',
        description : 'This research proposes a Deep Learning model using OpenCV to identify Indian cuisine and estimate the caloric content. The model attains 99.19% accuracy on the training data and 95.30% accuracy on the testing data for Indian cuisine recognition.',
        service : 'Elsevier',
        date : 'February 2022',
        link : 'https://doi.org/10.1016/j.matpr.2022.03.122'
    },
    {
        type : 'Patent',
        name: 'Automatic Corona Detection using Thermal Scanner and RFID reader for the Travellers in Motor Vehicle',
        description : 'The patent relates to a more efficient and less physical way of tracking COVID-19. More particularly, it relates to the MOTOR VEHICLE CORONA DETECTOR to identify whether a passenger in a vehicle is suspected of having COVID-19 or not.',
        service: 'Indian Patent Office',
        date : 'December 2020',
        link : 'https://iprsearch.ipindia.gov.in/PublicSearch/PublicationSearch/ApplicationStatus'
    }
]

const Publications = () => {
    return (
        <>
            <div className="publications-main-container">
                <div className="projects-heading">
                    <h1>
                        <h1 style={{
                            ...animationTagStyle,
                            fontSize: 'calc(2rem + 1vw + 2vh)',
                        }}>
                            <span style={{
                                ...highlightText,
                                fontSize: 'calc(2rem + 1vw + 2vh)',
                            }}>
                                &lt;
                            </span>
                            Publications
                            <span style={{
                                ...highlightText,
                                fontSize: 'calc(2rem + 1vw + 2vh)',
                            }}>
                                &gt;
                            </span>
                        </h1>
                    </h1>
                </div>
                <div className="publication-list-container">
                    {
                        PublicationsList.map((publication) => {
                            return (
                                <div className="publication-list-item">
                                    <div className="publication-details">
                                        <div className="publication-name">
                                            <h2>
                                                Publication Title
                                            </h2>
                                            <h1>
                                                {publication.name}
                                            </h1>
                                        </div>
                                        <div className="publication-type">
                                            <h2>
                                                Type
                                            </h2>
                                            <h1>
                                                {publication.type}
                                            </h1>
                                        </div>
                                        <div className="publication-description">
                                            <h2>
                                                Description
                                            </h2>
                                            <h1>
                                                {publication.description}
                                            </h1>
                                        </div>
                                        <div className="publication-service">
                                            <h2>
                                                Publication Service
                                            </h2>
                                            <h1>
                                                {publication.service}
                                            </h1>
                                        </div>
                                        <div className="publication-date">
                                            <h2>
                                                Date Published
                                            </h2>
                                            <h1>
                                                {publication.date}
                                            </h1>
                                        </div>
                                        <div className="publication-link">
                                            <h2>
                                                Link
                                            </h2>
                                            <h1>
                                                <a href={publication.link}>
                                                    {publication.link.split
                                                        ('/').slice(-1)[0]}
                                                </a>
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default Publications