import React from 'react'
import './Projects.css'
import ParaNeed from '../Projects/ProjectImages/project_preview/ParaNeed.png'
import SpeechToImage from '../Projects/ProjectImages/project_preview/SpeechToImage.png'
import DepressionDetection from '../Projects/ProjectImages/project_preview/DepressionDetection.png'
import ImageEditorSoftware from '../Projects/ProjectImages/project_preview/ImageEditorSoftware.png'
import AccesibilityBrowser from '../Projects/ProjectImages/project_preview/AccessibilityBrowser.png'
import Home2Home from '../Projects/ProjectImages/project_preview/Home2Home.png'
import { HTMLTagStyle, normalText, highlightText, animationTagStyle } from '../MainIntro/CustomStyles'

const Projects = () => {

  const projects = [
    {
      name: 'Home2Home',
      description: 'An Online food delivery website that delivers food from your supplier to your home, mainly focusing on the delivery of native home-cooked food.',
      techStack: 'ReactJS | MongoDB | NodeJS | ExpressJS | Stripe API',
      carouselIcon: Home2Home
    },
    {
      name: 'ParaNeed',
      description: 'An Amalgamation of E-Commerce and Doctor`s Chatting Consultation Platform.',
      techStack: 'ReactJS | MongoDB | NodeJS | ExpressJS | Stripe API | SocketIO',
      carouselIcon: ParaNeed
    },
    {
      name: 'Image Editor Software',
      description: 'An Image Editor Software that can perform various operations on images.',
      techStack: 'Java | Swing | MVC | Design Patterns | JUnit | Git',
      carouselIcon: ImageEditorSoftware
    },
    {
      name: 'Speech2Image',
      description: 'A website that converts speech to image facilitating the specially abled people to communicate with others.',
      techStack: 'ReactJS | MongoDB | Flask | Selenium | RestAPI',
      carouselIcon: SpeechToImage
    },
    {
      name: 'Depression Detection',
      description: 'Web Application that detects depression based on the user`s Spotify and Twitter data.',
      techStack: 'ReactJS | NodeJS | ExpressJS | Spotify API | Twitter API | Machine Learning',
      carouselIcon: DepressionDetection
    },
    {
      name: 'Accesibility Browser with GPT 3 Assistant',
      description: 'A PyQt browser, controlled by the eye and mouth movements of disabled individuals allowing for easier internet access, complemented by a ChatGPT API-powered AI chatbot.',
      techStack: 'PyQt | OpenCV | Python | ChatGPT API',
      carouselIcon: AccesibilityBrowser
    }
  ]

  return (

    <div className="main-projects-container">
      <div className="projects-heading">
        <h1>
          <h1 style={{
            ...animationTagStyle,
            fontSize: 'calc(2rem + 1vw + 2vh)',
          }}>
            <span style={{
              ...highlightText,
              fontSize: 'calc(2rem + 1vw + 2vh)',
            }}>
              &lt;
            </span>
            Projects
            <span style={{
              ...highlightText,
              fontSize: 'calc(2rem + 1vw + 2vh)',
            }}>
              &gt;
            </span>
          </h1>
        </h1>
      </div>
      <div className="projects-grid-container">
        {
          projects.map((project, index) => {
            return (
              <>
                <div className="projects-preview-container">
                  <div className="projects-preview-window-container">
                    <h2 style={{
                      ...normalText,
                      textAlign: 'left',
                      fontSize: 'calc(0.6rem + 0.4vw + 1.2vh)',
                    }}>
                      {project.name}
                    </h2>
                    <div className="preview-image-container" style={{
                      backgroundImage: `url(${project.carouselIcon})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                    }}>
                    </div>
                  </div>
                  <div className="project-details-container">
                    <h2 style={{
                      ...normalText,
                      textAlign: 'left',
                      fontSize: 'calc(0.4rem + 0.3vw + 0.9vh)',
                    }}>
                      {project.description}
                    </h2>
                    <h2 style={{
                      ...HTMLTagStyle,
                    }}>
                      Tech Stack:
                    </h2>
                    <h2 style={{
                      ...normalText,
                      textAlign: 'left',
                      fontSize: 'calc(0.2rem + 0.4vw + 1.2vh)',
                    }}>
                      {project.techStack}
                    </h2> <br />
                  </div>
                </div>
                {
                  index !== projects.length - 1 && (
                    <hr />
                  )
                }
              </>
            )
          })
        }
      </div>
      <div className="cards-container">
        <div className="card">
        </div>
      </div>
    </div>

    // <div className="main-projects-container">
    //   <div className='projects-carousel-container'>
    //     <div id="big-circle" className="circle big">
    //       {
    //         projects.map((project, index) => {
    //           return (
    //             <div className="circle" style={{
    //               backgroundImage: `url(${project.carouselIcon})`,
    //               backgroundSize: 'cover',
    //               backgroundPosition: 'center',
    //               backgroundRepeat: 'no-repeat',
    //             }}>
    //               <h2 style={{
    //                 opacity: '1',
    //               }}>
    //                 p
    //               </h2>
    //             </div>
    //           )
    //         })
    //       }
    //     </div>
    //   </div>
    //   <div className="projects-preview-container">
    //     <div className="projects-preview-window-container">
    //         <h2 style={{
    //           ...HTMLTagStyle,
    //         }}>
    //           Project Preview:
    //         </h2>
    //         <div className="preview-image-container">
    //           <img src={
    //             {
    //               0: DummyBG,
    //               1: DummyBG,
    //               2: DummyBG,
    //               3: DummyBG,
    //               4: DummyBG,
    //               5: DummyBG,
    //               6: DummyBG,
    //               7: DummyBG,
    //             }[0]
    //           } alt="" />
    //         </div>
    //     </div>
    //     <div className="project-details-container">
    //       <h2 style={{
    //         ...HTMLTagStyle,
    //       }}>
    //         Project Name:
    //       </h2>
    //       <h2 style={{
    //         ...normalText,
    //         textAlign: 'left',
    //         fontSize: 'calc(1rem + 0.4vw + 1.2vh)',
    //       }}>
    //         {projects[0].name}
    //       </h2> <br />
    //       <h2 style={{
    //         ...HTMLTagStyle,
    //       }}>
    //         Description:
    //       </h2>
    //       <h2 style={{
    //         ...normalText,
    //         textAlign: 'left',
    //         fontSize: 'calc(1rem + 0.3vw + 0.9vh)',
    //       }}>
    //         {projects[0].description}
    //       </h2> <br />
    //       <h2 style={{
    //         ...HTMLTagStyle,
    //       }}>
    //         Tech Stack:
    //       </h2>
    //       <h2 style={{
    //         ...normalText,
    //         textAlign: 'left',
    //         fontSize: 'calc(1rem + 0.4vw + 1.2vh)',
    //       }}>
    //         {projects[0].techStack}
    //       </h2> <br />
    //     </div>
    //   </div>
    // </div>
  )
}

export default Projects