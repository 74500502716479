import React from 'react'
import './Connect.css'
import MailColor from '../images/logos/Mail-color.png'
import LinkedInColor from '../images/logos/LinkedIn-color.png'
import GitHubColor from '../images/logos/GitHub-color.png'
import InstagramColor from '../images/logos/Instagram-color.png'

const Connect = () => {
    return (
        <>
            <div className="connect-main-container">
                <div className="connect-line-container"></div>
                <div className="connect-button-container">
                    <button>
                        <a href="mailto:sathiyanarayanan.a@northeastern.edu">
                            <img src={
                                MailColor
                            } alt="" />
                        </a>
                    </button>
                    <button>
                        <a href="https://www.linkedin.com/in/ashwin-sathiyanarayanan-996483196/">
                            <img src={
                                LinkedInColor
                            } alt="" />
                        </a>
                    </button>
                    <button>
                        <a href="https://github.com/ash-02">
                            <img src={
                                GitHubColor
                            } alt="" />
                        </a>
                    </button>
                    <button>
                        <a href="https://www.instagram.com/_.ash._.02._/">
                            <img src={
                                InstagramColor
                            } alt="" />
                        </a>
                    </button>
                </div>
                <div className="connect-line-container"></div>
            </div>
        </>
    )
}

export default Connect