import React from 'react'

const NavPanel = () => {

    return (
        <>
            <div className="bubble-container">
                <a href="#projects" onClick={(event) => {
                    event.preventDefault();
                    const section2 = document.getElementById('projects');
                    section2.scrollIntoView({ behavior: 'smooth' });
                }}>
                    <div className="bubble" style={{
                        top: '10%',
                        left: '10%',
                        position: 'absolute',
                        '@media (max-width: 768px)': {
                            display: 'none',
                        }
                    }}>
                        Projects
                    </div>
                </a>
                <a href="#workexp" onClick={(event) => {
                    event.preventDefault();
                    const section2 = document.getElementById('workexp');
                    section2.scrollIntoView({ behavior: 'smooth' });
                }}>
                    <div className="bubble" style={{
                        top: '35%',
                        left: '30%',
                        position: 'absolute',
                    }}>WorkExp</div>
                </a>
                <a href="#publications" onClick={(event) => {
                    event.preventDefault();
                    const section2 = document.getElementById('publications');
                    section2.scrollIntoView({ behavior: 'smooth' });
                }}>
                    <div className="bubble" style={{
                        top: '60%',
                        left: '0%',
                        position: 'absolute',
                        fontSize: '1.5rem'
                    }}>Publications</div>
                </a>
            </div>
            {/* <div className="bubble-container" style={{
                height: '100vh',
                width: '40%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                color: 'white',
                position: 'relative',
            }}>
                <div className="bubble" style={{
                    top: '10%',
                    left: '10%',
                    position: 'absolute',
                }}>Projects</div>
                <div className="bubble" style={{
                    top: '35%',
                    left: '30%',
                    position: 'absolute',
                }}>WorkExp</div>
                <div className="bubble" style={{
                    top: '60%',
                    left: '0%',
                    position: 'absolute',
                }}>Education</div>
            </div> */}
        </>
    )
}

export default NavPanel